let localStorageState = null;
const prefix = 'm_';
const setItem = (key, value) => {
    let _key = prefix + key;
    if (value === undefined || value === null) {
        removeItem(key);
        return;
    }

    if (typeof value === 'object') {
        if (value instanceof Date) {
            window.localStorage.setItem(_key + '_s', value);
        } else {
            window.localStorage.setItem(_key, JSON.stringify(value));
        }
    } else {
        if (typeof value === 'string') {
            window.localStorage.setItem(_key + '_s', value);
        } else {
            window.localStorage.setItem(_key, value);
        }
    }
};
const getItem = (key) => {
    let _key = prefix + key;
    let value = window.localStorage.getItem(_key + '_s');
    if (typeof value === 'string') {
        return value;
    }
    value = window.localStorage.getItem(_key);
    if (value) {
        try {
            return JSON.parse(value);
        } catch (e) {
            window.sendNodeLog && window.sendNodeLog(`client-error-parse`, window.location.href, 0, e);
            return value;
        }
    }
    return value;
};

const removeItem = (key) => {
    let _key = prefix + key;
    window.localStorage.removeItem(_key + '_s');
    window.localStorage.removeItem(_key);
};
export const initalStorage = () => {
    if (window.localStorage) {
        let movotoLocalStorage = window.localStorage.getItem('movotoLocalStorage');
        if (movotoLocalStorage) {
            try {
                movotoLocalStorage = JSON.parse(movotoLocalStorage);
                Object.keys(movotoLocalStorage).forEach((key) => {
                    let value = movotoLocalStorage[key];
                    setItem(key, value);
                });
            } catch (e) {
                movotoLocalStorage = null;
            }
            window.localStorage.removeItem('movotoLocalStorage');
        }
    } else {
        window.sendNodeLog && window.sendNodeLog(`client-error-storage`, window.location.href, 0);
    }
};
export const setStorage = (key, value) => {
    if (window.localStorage) {
        setItem(key, value);
    } else {
        if (!localStorageState) {
            localStorageState = {};
        }
        localStorageState[key] = value;
    }
};

export const getStorage = (key) => {
    if (window.localStorage) {
        return getItem(key);
    } else {
        if (!localStorageState) {
            localStorageState = {};
        }
        return localStorageState[key];
    }
};
export const removeStorage = (key) => {
    if (window.localStorage) {
        removeItem(key);
    } else {
        if (!localStorageState) {
            localStorageState = {};
            return;
        }
        delete localStorageState[key];
    }
};
export const setSessionStorage = (key, value) => {
    if (window.sessionStorage) {
        window.sessionStorage.setItem(key, value);
    }
};
export const getSessionStorage = (key) => {
    if (window.sessionStorage) {
        return window.sessionStorage.getItem(key);
    }
};
