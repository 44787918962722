import $eventBus from '@/common/providers/eventbus';
var context = window.__INITIAL_STATE__;
var loadedBrankrate = 0;
var loadedCmLinks = 0;
const getStringWithCtx = function(template, ctx){
    return new Function("return `"+template +"`;").call(ctx);
}
const openLinkWithTrack = function (linkOptions, ga) {
    if (ga) {
        $eventBus.$emit('ga', ga);
    } else {
        console.error(`notrack:${linkOptions.url}`);
    }
    window.open(linkOptions.url, linkOptions.target || '_blank');
}
export default {
    loadInsurancePop: function(zipcode, triggerId = '') {
        var url = `https://links.cordlessmedia.com/staticlink/89984/89984_textLink_01/textLink/insurance?zipcode=${zipcode}`;
        console.log(url);
        if(triggerId) {
            $eventBus.$emit('ga', { ec: 'partner', en: 'insurance_session', el: 'cordless', props:{link_name: triggerId} });
        }
        window.open(url, '_blank');
    },
    loadBankratePop: function(){
        return new Promise((resolve) => {
            //has load
            if (loadedBrankrate === 2) {
                resolve(true);
            } else if (loadedBrankrate === 0) {
                loadedBrankrate = 1;
                $.preload({files: ['https://elf.bankrate.com/embedded.umd.min.js'], type: 'js'}).then(function(){
                    loadedBrankrate = 2;
                    resolve(true);
                });
            }
        });
    },
    loadCmLinks: function(){
        return new Promise((resolve) => {
            if ($.getStorage('disableCmlinks')) {
                console.log('Disabled CMLinks...');
                resolve(true);
            }
            if (loadedCmLinks === 2) {
                resolve(true);
            } else if (loadedCmLinks === 0) {
                loadedCmLinks = 1;
                $.preload({files: ['https://cdn.cordlessmedia.com/ad-wrapper/89984/cmlinks.min.js'], type: 'js'}).then(function(){
                    loadedCmLinks = 2;
                    resolve(true);
                });
            }
        });
    },
    loadCmAds: function() {
        return new Promise((resolve) => {
            if (window.adsLoaded === 2) {
               resolve(window.cmWrapper);
            } else {
                $(document).one('adsLoaded', function(e, cmWrapper) {
                   resolve(cmWrapper);
                });
                if (!window.adsLoaded) {
                    window.adsLoaded = 1;
                    //get ready to load
                    var adsUrl = 'https://ads.cordlessmedia.com/ad-wrapper/89984/cm.min.js';
                    $.preload({files: [adsUrl], type: 'js'}).then(function() {
                        if(!window.cmWrapper){
                            window.cmWrapper = {};
                        }
                        if(!window.cmWrapper.que){
                            window.cmWrapper.que = [];
                        }
                        window.adsLoaded = 2;
                        $(document).trigger('adsLoaded', window.cmWrapper);
                    });
                }
            }
        });
    },
    click3rdParty: function(setting, ctx){
        if(setting.href){
            let link = getStringWithCtx(setting.href, ctx);
            window.open(link, '_blank');
            if(setting.ec && setting.ea){
                $eventBus.$emit('ga', { ec: setting.ec, en: setting.ea, el: setting.el || '', props:{link_name: setting.cta} });
            }
        }
    },
    loadDefaultMRCPopUp: function(trigger, cta){
        let fallback = '';
        let isNewDpp = document.querySelector('.newDpp');
        if (trigger === 'apply') {
            fallback = 'getpreapprovedATF_LP';
        } else if (trigger === 'getpreapproved') {
            fallback = 'getpreapproved_LP';
        } else if (trigger === 'findalender') {
            fallback = 'findlender_LP';
        }
        if (fallback && isNewDpp) {
            fallback += '_NEW';
        }
        return openLinkWithTrack(
            { url: `${__INITIAL_STATE__.appUrl}mortgage/mrc/?title=getpreapproved&fallback=${fallback}` },
            { ec: 'partner_mortgage_listings_session', en: 'long_form', el: 'MRC', props: { link_name: cta } }
        );
    },
    loadMortgagePopUp: function(trigger, pid, cta) {
        $eventBus.$emit('dialog-center', {
            name: '3wayPayment',
            trigger: 'loadOjolink',
            opt: {
                showRefinance: true,
                pid: pid,
                triggerId: trigger,
                cta: cta,
            },
        });
    },
    openBankratePop: function(inputOpt){
        if(!inputOpt){
            return;
        }
        var sendOpt = {
            price: 300000,
            pid: 'movoto_branded',
            triggerId: 'getquotes',
            utm_source: 'ojo_movoto',
            customization: 'movotoShort',
            zipcode: '',
            address: '',
            city: '',
            state: '',
            email: '',
            phone: '',
            firstName: '',
            lastName: '',
        };

        var winOpt = {};
        if(window && window.location && window.location.search){
            winOpt = $.parseQueryString(window.location.search);
        }
        Object.assign(sendOpt, winOpt);
        Object.assign(sendOpt, inputOpt);
        if(sendOpt.propertyType){
            if (sendOpt.propertyType === 'MOBILE') {
                sendOpt.pid = 'movoto_mobile';
            } else if (sendOpt.propertyType === 'LAND') {
                sendOpt.pid = 'movoto_land';
            }
        }
        if(sendOpt.fullName && !sendOpt.firstName && !sendOpt.lastName){
            const names = sendOpt.fullName.split(' ');
            sendOpt.firstName = names[0];
            sendOpt.lastName = names[1];
        }
        if(sendOpt.url === 'credit' || sendOpt.url === 'rentalCredit'){
            let linkName = 'checkhereshortform', url = '', el = '';
            if(sendOpt.url == 'credit') {
                if(sendOpt.houseRealStatus === 'ACTIVE' || sendOpt.houseRealStatus === 'SOLD' || sendOpt.houseRealStatus === 'OFF_MARKET') {
                    if(sendOpt.triggerId === 'checkInterest' || (sendOpt.triggerId == 'checkYourCredit' && sendOpt.trigger == 'propertyMortgage')) {
                        linkName = `${sendOpt.propertyType}_calculatorsection`;
                        el = 'cordless';
                        url = `https://links.cordlessmedia.com/staticlink/89984/89984_textLink_07/textLink/credit`;
                    }
                    else if (sendOpt.triggerId === 'creditCheck') {
                        linkName = `${sendOpt.propertyType}__thankyoupage`;
                    }
                    else if(sendOpt.triggerId === 'homebuyers_checkYourCredit') {
                            linkName = `${sendOpt.propertyType}_hoafee`;
                            el = 'cordless';
                            url = `https://links.cordlessmedia.com/staticlink/89984/89984_textLink_07/textLink/credit`;
                    }
                }
                if((sendOpt.triggerId).indexOf('creditCheckRent') > -1) {
                    linkName = (sendOpt.triggerId).indexOf('MultiMsg') > -1 ? 'rental_thankyoupage_mm': 'rental_thankyoupage';
                    el = 'cordless';
                    url = `https://links.cordlessmedia.com/staticlink/89984/89984_textLink_07/textLink/credit`;
                }
            }
            else if(sendOpt.url === 'rentalCredit') {
                linkName = 'rental_abovethefold';
                url = 'https://links.cordlessmedia.com/staticlink/89984/89984_textLink_07/textLink/credit'
            }

            if(linkName) {
                $eventBus.$emit('ga', { ec: 'partner', en: 'credit_monitoring_session', el: el ? el : 'cordless', props:{link_name: `${linkName}`} });
            }
            window.open(url ? url : 'https://links.cordlessmedia.com/staticlink/89984/89984_textLink_07/textLink/credit', '_blank');
            return;
        }
        var isMinorProperty = sendOpt.price < 100000 || ['LAND', 'MOBILE'].includes(sendOpt.propertyType);

        var districtLendingPartnerUrl = `https://districtlending.com/movoto/?zipcode=${sendOpt.zipcode}`;
        var url = sendOpt.url;
        var elDict = {
            preapprove: 'bankrate',
            preapproveapply: 'bankrate',
            preapproveCalc: 'bankrate',
            prequalify: 'bankrate',
            veteranseligibility: 'Bankrate_Veterans_topcalc',
            veteranseligibility1: 'Veterans United Top Calc',
            veteranseligibility1NewDpp: 'Veterans United Top Calc (New DPP)',
            veteranseligibility2: 'Veterans United Top Calc',
            veteranseligibility3: 'VeteransUnited_topcalc2',
            veteranscanafford: 'Veterans United Below Calc',
            veteranscanafford1: 'VeteransUnited_TYP',
            veteranscanafford2: 'VeteransUnited_TYP_V2',
            veteranscanafford3: 'Veterans United Below Calc',
            veteranscanafford4: 'Bankrate_Veterans_bottomcalc',
            veteranscanafford6: 'Veterans United Below Calc',
            veteranscanafford6NewDpp: 'Veterans United Below Calc (New DPP)',
            veteranslink: 'Bankrate_Veterans_TYP'
        };
        var category;
        var props = {}

        if(sendOpt.cta) {
            props = {
                link_name: sendOpt.cta
            }
        }
        if(url === 'mortgagepages') {
            window.open(`https://links.cordlessmedia.com/staticlink/89984/89984_textLink_18/PreApprovalNavPageApply/mortgage?zipcode=${sendOpt.zipcode}&state=${sendOpt.state}`, '_blank');
            $eventBus.$emit('ga', { ec: 'partner_mortgage_page_session', en: 'lender_site', el: 'Cordless', props:{link_name: 'mortgagepages' }});
            return;
        }
        if (url === 'mortgagepagesnaf') {
            window.open(`https://www.newamericanfunding.com/lfj/mov/?refinance=32575050&purchase=32575100&phone=8554390154&utm_source=MVTO&utm_medium=display&utm_content=default&utm_campaign=MVTO_Mortgage&lp=pr&lt=all&sub_id=&pub_id=mortgagepage&utm_term=default`, '_blank');
            $eventBus.$emit('ga', { ec: 'partner_mortgage_page_session', en: 'lender_site', el: 'New American Funding', props: { link_name: 'mortgagepages' } });
            return;
        }
        if (url === 'mortgagepagesbankrate') {
            return openLinkWithTrack(
                { url: 'https://www.rocketmortgage.com/alf/wham-orbit/loanType?qpv=blocknomovement&qls=MVT_affrktxx.movotopurc&aff_sub=' },
                { ec: 'partner_mortgage_page_session', en: 'lender_site', el: 'Rocket', props: { link_name: 'mortgagepages' } }
            );
        }
        if(url === 'veteranshub1') {
            window.open('https://ad.doubleclick.net/ddm/trackclk/N7384.146215MOVOTO/B27281448.361482107;dc_trk_aid=552469127;dc_trk_cid=188175518;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;ltd=', '_blank');
            $eventBus.$emit('ga', { ec: 'partner', en: 'mortgage_session', el: 'VeteransUnited_vethub1', props: {link_name: 'Prequalify for a 0% Down VA Loan'}});
            return;
        }
        if(url === 'veteranshub2') {
            window.open('https://ad.doubleclick.net/ddm/trackclk/N7384.146215MOVOTO/B27281448.361665157;dc_trk_aid=552630610;dc_trk_cid=188178971;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;ltd=', '_blank');
            $eventBus.$emit('ga', { ec: 'partner', en: 'mortgage_session', el: 'VeteransUnited_vethub2', props: {link_name: 'Get Pre-Qualified for a VA Loan'}});
            return;
        }
        if(url === 'veteransdrawer') {
            return openLinkWithTrack(
                { url: 'https://ad.doubleclick.net/ddm/trackclk/N7384.146215MOVOTO/B27281448.361855638;dc_trk_aid=552469235;dc_trk_cid=187987699;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;ltd=' },
                { ec: 'long_form', en: 'partner_mortgage_listing_session', el: 'Veterans United Drop Drawer', props: {link_name: 'Prequalify for a 0% Down VA Loan'}}
            );
        }
        if(url === 'rent2own') {
            window.open('https://apply.landis.com/movoto/?utm_source=movoto&utm_medium=paid_partner&utm_campaign=active_listing&utm_content=activeATF', '_blank');
            $eventBus.$emit('ga', { ec: 'partner', en: 'alternative_financing_session', el: 'Landis', props: {link_name: 'Credit issues or no down payment?_ActiveATF'}});
            return;
        }
        if(url === 'typautopop'){
            window.open('https://apply.landis.com/movoto/?utm_source=movoto&utm_medium=paid_partner&utm_campaign=rental_listing&utm_content=postreSF', '_blank');
            $eventBus.$emit('ga', { ec: 'partner', en: 'alternative_financing_session', el: 'Landis', props: {link_name: 'Rent-to-own options_postreSF'}});
            return;
        }
        if(url === '9611A'){
            window.open('https://apply.landis.com/movoto/?utm_source=movoto&utm_medium=paid_partner&utm_campaign=active_listing&utm_content=paymentcalculator', '_blank');
            $eventBus.$emit('ga', { ec: 'partner', en: 'alternative_financing_session', el: 'Landis', props: {link_name: 'Credit issues? No down payment? Apply for rent-to-own!'}});
            return;
        }
        if(url === '9747A'){
            window.open('https://www.divvyhomes.com/?ref=affiliates&utm_source=movoto&utm_medium=partners&utm_content=paymentcalculator', '_blank');
            $eventBus.$emit('ga', { ec: 'partner', en: 'alternative_financing_session', el: 'Divvy', props: {link_name: 'Credit issues? No down payment? Apply for rent-to-own!'}});
            return;
        } else if(url === '9747B'){
            window.open('https://www.divvyhomes.com/?ref=affiliates&utm_source=movoto&utm_medium=partners&utm_content=belowcalculator', '_blank');
            $eventBus.$emit('ga', { ec: 'partner', en: 'alternative_financing_session', el: 'Divvy', props: {link_name: 'Credit issues or no down payment? Apply for rent-to-own!'}});
            return;
        }
        if(url === 'nonBrandedTYP'){
            window.open(`https://links.cordlessmedia.com/staticlink/89984/89984_textLink_19/Sure/mortgage??zipcode=${sendOpt.zipcode}&state=${sendOpt.state}&price=${sendOpt.price}`, '_blank');
            $eventBus.$emit('ga', { ec: 'partner', en: 'mortgage_session', el: 'Cordless_postRE',cd29: 'Sure'});
            return
        }
        if(url == 'renttoown') {
            //cw-9699
            if(sendOpt.triggerId === 'renttoown1') {
                window.open('https://apply.landis.com/movoto/?utm_source=movoto&utm_medium=paid_partner&utm_campaign=landing_page&utm_content=RTOLP', '_blank');
                $eventBus.$emit('ga', { ec: 'partner', en: 'alternative_financing_session', el: 'Landis', props: {link_name: `${sendOpt.cta}- LP`}});
                return;
            }
            else if(sendOpt.triggerId === 'renttoown2') {
                window.open('https://www.divvyhomes.com/partners/movoto?ref=affiliates&utm_source=movoto&utm_medium=partners&utm_content=RTOLP', '_blank');
                $eventBus.$emit('ga', { ec: 'partner', en: 'alternative_financing_session', el: 'Divvy', props: {link_name: `${sendOpt.cta}- LP`}});
                return;
            }
            //cw-10444
            else if(sendOpt.triggerId === 'renttoown4') {
                window.open('https://apply.landis.com/movoto/?utm_source=movoto&utm_medium=paid_partner&utm_campaign=active_listing&utm_content=listingsSF', '_blank');
                $eventBus.$emit('ga', { ec: 'partner', en: 'alternative_financing_session', el: 'Landis', props: {link_name: 'Rent-to-own options_listingsSF'}});
                return;
            }
        }

        if(url === 'rentaltop'){
            if(sendOpt.triggerId === 'rentaltop1') {
                window.open('https://apply.landis.com/movoto/?utm_source=movoto&utm_medium=paid_partner&utm_campaign=rental_listing&utm_content=top', '_blank');
                $eventBus.$emit('ga', { ec: 'partner', en: 'alternative_financing_session', el: 'Landis', props: {link_name: sendOpt.cta}});
                return;
            }
        }else if(url === 'rentalbottom'){
            if(sendOpt.triggerId === 'rentalbottom1') {
                const utm_content =  sendOpt.isAutoPop ? 'utm_content=autopop' : 'utm_content=bottom';
                const cd29 = sendOpt.isAutoPop ? 'Apply for rent-to-own' : sendOpt.cta;
                window.open('https://apply.landis.com/movoto/?utm_source=movoto&utm_medium=paid_partner&utm_campaign=rental_listing&' + utm_content, '_blank');
                $eventBus.$emit('ga', { ec: 'partner', en: 'alternative_financing_session', el: 'Landis', props: {link_name: cd29}});
                return;
            }
            else if(sendOpt.triggerId === 'rentalbottom2') {
                const utm_content =  sendOpt.isAutoPop ? 'utm_content=autopop' : 'utm_content=button';
                const cd29 = sendOpt.isAutoPop ? 'Apply for rent-to-own' : sendOpt.cta;
                window.open('http://www.divvyhomes.com/?ref=affiliates&utm_source=movoto&utm_medium=partners&' + utm_content, '_blank');
                $eventBus.$emit('ga', { ec: 'partner', en: 'alternative_financing_session', el: 'Divvy', props: {link_name: cd29}});
                return;
            }
        } else if(url === 'rentaltyp'){
            if(sendOpt.triggerId === 'rentaltyp1') {
                window.open('https://apply.landis.com/movoto/?utm_source=movoto&utm_medium=paid_partner&utm_campaign=rental_listing&utm_content=TYP', '_blank');
                $eventBus.$emit('ga', { ec: 'partner', en: 'alternative_financing_session', el: 'Landis', props: {link_name: `${sendOpt.cta}_TYP`}});
                return;
            }
            else if(sendOpt.triggerId === 'rentaltyp3') {
                window.open('https://www.movoto.com/homeservices/?utm_medium=partnership&utm_source=cma-ojo-movoto&utm_campaign=rentalTYP','_blank');
                $eventBus.$emit('ga', { ec: 'partner', en: 'home_services_session', el: 'Thumbtack', props: {link_name: sendOpt.cta}});
                return;
            }
        }
        if(url === 'cordlessTYP') {
            window.open(`https://links.cordlessmedia.com/staticlink/89984/89984_textLink_19/Sure/mortgage?zipcode=${sendOpt.zipcode}&state=${sendOpt.state}&price=${sendOpt.price}`, '_blank');
            $eventBus.$emit('ga', { ec: 'mortage_partner_TYP', en: 'cpc ', el: 'Cordless Post RE Click', props: {link_name: sendOpt.cta}});
            return;
        }
        if (url === 'calcpayment2lender') {
            switch (context.splits['movoto-calcpayment-cw-12068']) {
                case 'on1':
                    window.open(`https://gardenstateloans.com/movoto/?utm_campaign=movoto&zipcode=${sendOpt.zipcode}`, '_blank');
                    $eventBus.$emit('ga', { ec: 'partner_mortgage_listings_session_calcpayment', en: 'lender_site', el: 'Garden State', props: { link_name: sendOpt.cta } });
                    return;
                case 'on2':
                    window.open(`https://www.farmersbankkc.com/quote/?utm_campaign=movoto&zipcode=${sendOpt.zipcode}`, '_blank');
                    $eventBus.$emit('ga', { ec: 'partner_mortgage_listings_session_calcpayment', en: 'lender_site_lead_flow', el: 'Farmers Bank', props: { link_name: sendOpt.cta } });
                    return;
                case 'on3':
                    window.open(`https://movoto-farmersbank.webflow.io/?utm_campaign=movoto&zipcode=${sendOpt.zipcode}`, '_blank');
                    $eventBus.$emit('ga', { ec: 'partner_mortgage_listings_session_calcpayment', en: 'lender_site_lead_flow', el: 'Farmers Bank', props: { link_name: sendOpt.cta } });
                    return;
                case 'on4':
                    window.open(`https://districtlending.com/quote/?utm_campaign=movoto&zipcode=${sendOpt.zipcode}`, '_blank');
                    $eventBus.$emit('ga', { ec: 'partner_mortgage_listings_session_calcpayment', en: 'lender_site_lead_flow', el: 'District Lending', props: { link_name: sendOpt.cta } });
                    return;
                case 'on7':
                    window.open('https://ad.doubleclick.net/ddm/trackclk/N7384.146215MOVOTO/B27281448.381250021;dc_trk_aid=572143378;dc_trk_cid=204826850;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;ltd=;dc_tdv=1', '_blank');
                    $eventBus.$emit('ga', { ec: 'partner_mortgage_listings_session', en: 'lender_site', el: 'Veterans United ATF', props: { link_name: sendOpt.cta } });
                    return;
                case 'on8':
                    window.open('https://ad.doubleclick.net/ddm/trackclk/N7384.146215MOVOTO/B27281448.382782013;dc_trk_aid=573643568;dc_trk_cid=205878688;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;ltd=;dc_tdv=1', '_blank');
                    $eventBus.$emit('ga', { ec: 'partner_mortgage_listings_session', en: 'lender_site', el: 'Veterans United ATF', props: { link_name: sendOpt.cta } });
                    return;
            }
        }
        if (sendOpt.triggerId === 'veteranscanafford5') {
            if(context.splits['movoto-veteranTYP-DualCTA-CW-1663'] === 'on') {
                $eventBus.$emit('ga', { ec: 'partner', en: 'mortgage_session', el: 'Veterans United', props: {link_name: `Continue_dualconfirmflow`}});
                window.open(`https://www.veteransunitedrealty.com/direct_v5/?lfuid=${sendOpt.hotleadId}&source=ojo`, '_blank');
            }else if(context.splits['movoto-veteranTYP-DualCTA-CW-873'] === 'on') {
                $eventBus.$emit('ga', { ec: 'partner', en: 'mortgage_session', el: 'Veterans United', props: {link_name: `Continue_dualconfirmflow`}});
                window.open(`https://www.veteransunitedrealty.com/direct_v5/?lfuid=${sendOpt.hotleadId}&source=ojo&pih=${context.user.sha256Data}`, '_blank');
            }else if(sendOpt.veteranTYPSplit){
                let queryParams = `&prop_val=${sendOpt.price}&prop_type=${sendOpt.propertyType}&city=${sendOpt.city}&state=${sendOpt.state}&zip=${sendOpt.zipcode}`;
                if(sendOpt.veteransType) {
                    queryParams = queryParams + `&mil_branch=${sendOpt.veteransType}`;
                }
                $eventBus.$emit('ga', { ec: 'partner', en: 'mortgage_session', el: 'Veterans United', props: {link_name: `${sendOpt.cta}_dedicatedTYPv3`}});
                window.open('https://www.veteransunited.com/lp/connect?cobrand=movoto&src=movoto-af&adg=movo-af-confirm-v3&cmp=short-flow' + queryParams, '_blank');
            }else
            {
                $eventBus.$emit('ga', { ec: 'partner', en: 'mortgage_session', el: 'Veterans United', props: {link_name: `${sendOpt.cta}_dedicatedTYP`}});
                window.open('https://ad.doubleclick.net/ddm/trackclk/N7384.146215MOVOTO/B27281448.338124602;dc_trk_aid=530094554;dc_trk_cid=172674781;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;ltd=', '_blank');
            }
            return;
        }

        if(!isMinorProperty && sendOpt.triggerId === 'apply' && context.splits['movoto-cpc-apply-cw-9238'] === 'on'){
            let linkToOpen = `https://ep.bankrate.com/hlink_redirects/brm_cpc.aspx?e=DG%2Bz7T9Zk6puw8AsmhIEvei5A2IDvbb%2B0sbrYRo5meGjtSAO4pyD3xlEK%2BWguEY99Jp%2BHaCtbREZOraip8uB2BEZfUkHyGu55CNNTZh3UIZGyaydCyh4TpTVDAq%2B%2FehZtBHIP%2Bs8nLWyRd4IK5v3qs4hES13t6laXaCIW%2FYhec6F5xViKHngUJfVNDh0ADWsQgUa9KjL%2FOzwHPGPmtpi0lcERTNfojNZyopJZtYhZeUNh0fVd5S3IzN%2B%2BBZUaSepk9Hfs4502Xgg4oWxeOKDx4I6fDIfBh947qZDlwX67fH7zrKz3925oy2xha%2FUC%2FuxwrGIK5qtoPKTXearpzSiGzpy74GZyfyNv0no%2FVg0vePfKCEdEbugQ4WlL%2FWOZoYYUB%2BrlsnOHWUTn01dW01eN7PCelBCyZX%2FIMuCllgZFig%2FZ39Fn%2BAl02vkILsokrLss%2B7Bmn5T%2BEv2R3foIuSBpw%3D%3D&reqid=10000000-1000-4000-8000-100000169977&lead=1&adsetid=169977&adsettype=cost_per_click&cs=0&pc=2&c=%2C%2C%2C%2Chttps%3A%2F%2Fwww.rocketmortgage.com%2Falf%2Fwham-orbit%2FloanType%3Fqpv%3Dblocknomovement%26qls%3DMVT_affrktxx.movotopurc%26aff_sub%3D%257Bbrlid%257D%26reqId%3D10000000-1000-4000-8000-100000169977%2C&iscpl=false&points=&dti=&directlink=true&utm_medium=web&utm_source=movoto&utm_campaign=getpreapprovedATF`
            if(sendOpt.isAppendUtmCta) {
                linkToOpen += `&utmcta=${sendOpt.triggerId}SF`
            }
            return openLinkWithTrack(
                { url: linkToOpen },
                { ec: 'partner_mortgage_listings_session', en: 'lender_site', el: 'Quicken', props:{link_name: 'Get Preapproved ATF'}}
            );
        } else if(!isMinorProperty && sendOpt.triggerId === 'apply' && context.splits['movoto-cpc-apply-cw-9238'] === 'on1') {
            return openLinkWithTrack(
                { url: 'https://www.rocketmortgage.com/alf/wham-orbit/loanType?qpv=blocknomovement&qls=MVT_affrktxx.movotopurc&aff_sub=' },
                { ec: 'partner_mortgage_listings_session', en: 'lender_site', el: 'Rocket', props:{link_name: 'Get Preapproved ATF'}}
            );
        } else if(!isMinorProperty && sendOpt.triggerId === 'apply' && context.splits['movoto-cpc-apply-cw-9238'] === 'on2') {
            const emailSha256 = context && context.user && context.user.sha256 ? `&cme=${context.user.sha256}` : '';
            return openLinkWithTrack(
                { url: `https://links.cordlessmedia.com/staticlink/89984/89984_textLink_09/ApplyNowButton/mortgage?zipcode=${sendOpt.zipcode}&state=${sendOpt.state}&city=${sendOpt.city}&address=${sendOpt.address}&price=${sendOpt.price}${emailSha256}` },
                { ec: 'partner_mortgage_listings_session', en: 'lender_site', el: 'Cordless', props: { link_name: 'Get Preapproved ATF' } }
            );
        } else if(!isMinorProperty && sendOpt.triggerId === 'apply' && context.splits['movoto-cpc-apply-cw-9238'] === 'on3') {
            let isNewDpp = document.querySelector('.newDpp');
            return openLinkWithTrack(
                { url: __INITIAL_STATE__.appUrl + 'mortgage/mrc/?title=getpreapproved&fallback=getpreapprovedATF_LP' + (isNewDpp ? '_NEW' : '') },
                { ec: 'partner_mortgage_listings_session', en: 'long_form', el: 'MRC', props: { link_name: 'Get Preapproved ATF - LP' } }
            );
        } else if(!isMinorProperty && sendOpt.triggerId === 'apply' && context.splits['movoto-cpc-apply-cw-9238'] === 'on4') {
            return openLinkWithTrack(
                { url: `https://gardenstateloans.com/movoto/?utm_campaign=movoto_apply&zipcode=${sendOpt.zipcode}` },
                { ec: 'partner_mortgage_listings_session', en: 'lender_site', el: 'Garden State', props: { link_name: 'Get Preapproved ATF' } }
            );
        } else if (!isMinorProperty && sendOpt.triggerId === 'apply' && context.splits['movoto-cpc-apply-cw-9238'] === 'on5') {
            return openLinkWithTrack(
                { url: 'https://onboarding.ownup.com/?utm_source=movoto&utm_medium=partnerchannel&utm_campaign=pilot&utm_term=getpreapprovedATF&utm_content=direct' },
                { ec: 'partner_mortgage_listings_session', en: 'long_form', el: 'OwnUp', props: { link_name: 'Get Preapproved ATF' } }
            );
        } else if(!isMinorProperty && sendOpt.triggerId === 'apply' && context.splits['movoto-cpc-apply-cw-9238'] === 'on6') {
            return openLinkWithTrack(
                { url: `https://www.newamericanfunding.com/lfj/mov/?refinance=31946757&purchase=31946858&phone=8559503731&utm_source=MVTO&utm_medium=display&utm_content=default&utm_campaign=MVTO_ActiveListings&lp=pr&lt=all&sub_id=${sendOpt.price}&pub_id=apply&utm_term=default` },
                { ec: 'partner_mortgage_listings_session', en: 'lender_site', el: 'New American Funding', props: { link_name: 'Get Preapproved ATF' } }
            );
        } else if(!isMinorProperty && sendOpt.triggerId === 'apply' && context.splits['movoto-cpc-apply-cw-9238'] === 'on7') {
            return openLinkWithTrack(
                { url: `https://ep.bankrate.com/hlink_redirects/brm_cpc.aspx?e=DG%2Bz7T9Zk6puw8AsmhIEvei5A2IDvbb%2B0sbrYRo5meGjtSAO4pyD3xlEK%2BWguEY99Jp%2BHaCtbREZOraip8uB2BEZfUkHyGu55CNNTZh3UIZGyaydCyh4TpTVDAq%2B%2FehZtBHIP%2Bs8nLWyRd4IK5v3qs4hES13t6laXaCIW%2FYhec6F5xViKHngUJfVNDh0ADWsQgUa9KjL%2FOzwHPGPmtpi0lcERTNfojNZyopJZtYhZeUNh0fVd5S3IzN%2B%2BBZUaSepk9Hfs4502Xgg4oWxeOKDx4I6fDIfBh947qZDlwX67fH7zrKz3925oy2xha%2FUC%2FuxwrGIK5qtoPKTXearpzSiGzpy74GZyfyNv0no%2FVg0vePfKCEdEbugQ4WlL%2FWOZoYYUB%2BrlsnOHWUTn01dW01eN7PCelBCyZX%2FIMuCllgZFig%2FZ39Fn%2BAl02vkILsokrLss%2B7Bmn5T%2BEv2R3foIuSBpw%3D%3D&reqid=10000000-1000-4000-8000-100000169977&lead=1&adsetid=169977&adsettype=cost_per_click&cs=0&pc=2&c=%2C%2C%2C%2Chttps%3A%2F%2Fwww.rocketmortgage.com%2Falf%2Fwham-orbit%2FloanType%3Fqpv%3Dblocknomovement%26qls%3DMVT_affrktxx.movotopurc%26aff_sub%3D%257Bbrlid%257D%26reqId%3D10000000-1000-4000-8000-100000169977%2C&iscpl=false&points=&dti=&directlink=true` },
                { ec: 'partner_mortgage_listings_session', en: 'lender_site', el: 'Quicken', props: { link_name: 'Get Preapproved ATF' } }
            );
        } else if(!isMinorProperty && sendOpt.triggerId === 'apply' && context.splits['movoto-cpc-apply-cw-9238'] === 'on8') {
            return openLinkWithTrack(
                { url: `https://links.cordlessmedia.com/staticlink/89984/89984_textLink_22/NewApplyNowButton/mortgage?zipcode=${sendOpt.zipcode}&state=${sendOpt.state}&price=${sendOpt.price}` },
                { ec: 'partner_mortgage_listings_session', en: 'lender_site', el: 'Cordless', props: { link_name: 'Get Preapproved ATF' } }
            );
        } else if(!isMinorProperty && sendOpt.triggerId === 'apply' && context.splits['movoto-cpc-apply-cw-9238'] === 'on10') {
            return openLinkWithTrack(
                { url: `https://districtlending.itclix.com/#home?zipcode=${sendOpt.zipcode}&utm_campaign=apply` },
                { en: 'lender_site_lead_pop', ec: 'partner_mortgage_listings_session', en: 'lender_site_lead_pop', el: 'District Lending', props: { link_name: 'Get Preapproved' } }
            );
        } else if(!isMinorProperty && ['getpreapproved','calcfindalender'].indexOf(sendOpt.triggerId) > -1 && context.splits['movoto-cpc-preapproved-cw-9240'] === 'on'){
            let linkToOpen = `https://ep.bankrate.com/hlink_redirects/brm_cpc.aspx?e=DG%2Bz7T9Zk6puw8AsmhIEvei5A2IDvbb%2B0sbrYRo5meGjtSAO4pyD3xlEK%2BWguEY99Jp%2BHaCtbREZOraip8uB2BEZfUkHyGu55CNNTZh3UIZGyaydCyh4TpTVDAq%2B%2FehZtBHIP%2Bs8nLWyRd4IK5v3qs4hES13t6laXaCIW%2FYhec6F5xViKHngUJfVNDh0ADWsQgUa9KjL%2FOzwHPGPmtpi0lcERTNfojNZyopJZtYhZeUNh0fVd5S3IzN%2B%2BBZUaSepk9Hfs4502Xgg4oWxeOKDx4I6fDIfBh947qZDlwX67fH7zrKz3925oy2xha%2FUC%2FuxwrGIK5qtoPKTXearpzSiGzpy74GZyfyNv0no%2FVg0vePfKCEdEbugQ4WlL%2FWOZoYYUB%2BrlsnOHWUTn01dW01eN7PCelBCyZX%2FIMuCllgZFig%2FZ39Fn%2BAl02vkILsokrLss%2B7Bmn5T%2BEv2R3foIuSBpw%3D%3D&reqid=10000000-1000-4000-8000-100000169977&lead=1&adsetid=169977&adsettype=cost_per_click&cs=0&pc=2&c=%2C%2C%2C%2Chttps%3A%2F%2Fwww.rocketmortgage.com%2Falf%2Fwham-orbit%2FloanType%3Fqpv%3Dblocknomovement%26qls%3DMVT_affrktxx.movotopurc%26aff_sub%3D%257Bbrlid%257D%26reqId%3D10000000-1000-4000-8000-100000169977%2C&iscpl=false&points=&dti=&directlink=true&utm_medium=web&utm_source=movoto&utm_campaign=getpreapproved`
            if(sendOpt.isAppendUtmCta) {
                linkToOpen += `&utmcta=${sendOpt.triggerId}SF`
            }
            return openLinkWithTrack(
                { url: linkToOpen },
                { ec: 'partner_mortgage_listings_session', en: 'lender_site', el: 'Quicken', props:{link_name: sendOpt.triggerId === 'calcfindalender' ? 'Get Prequalified': 'Get Preapproved' }}
            );
        } else if (!isMinorProperty && ['getpreapproved', 'calcfindalender'].indexOf(sendOpt.triggerId) > -1) {
            let cta = sendOpt.triggerId === 'calcfindalender' ? 'Get Prequalified': 'Get Preapproved';
            switch (context.splits['movoto-cpc-preapproved-cw-9240']) {
                case 'on1':
                    return openLinkWithTrack(
                        { url: 'https://www.rocketmortgage.com/alf/wham-orbit/loanType?qpv=blocknomovement&qls=MVT_affrktxx.movotopurc&aff_sub=' },
                        { ec: 'partner_mortgage_listings_session', en: 'lender_site', el: 'Rocket', props: { link_name: cta } }
                    );
                case 'on2':
                    return openLinkWithTrack(
                        { url: 'https://onboarding.ownup.com/?utm_source=movoto&utm_medium=partnerchannel&utm_campaign=pilot&utm_term=getpreapproved&utm_content=direct' },
                        { ec: 'partner_mortgage_listings_session', en: 'lender_site', el: 'PNC', props: { link_name: sendOpt.triggerId === 'calcfindalender' ? 'Get Prequalified': 'Start Mortgage Preapproval' } }
                    );
                case 'on3':
                    const emailSha256 = context && context.user && context.user.sha256 ? `&cme=${context.user.sha256}` : '';
                    return openLinkWithTrack(
                        { url: `https://links.cordlessmedia.com/staticlink/89984/89984_textLink_10/GetPreApproved/mortgage?zipcode=${sendOpt.zipcode}&state=${sendOpt.state}&city=${sendOpt.city}&address=${sendOpt.address}&price=${sendOpt.price}${emailSha256}` },
                        { ec: 'partner_mortgage_listings_session', en: 'lender_site', el: 'Cordless', props: { link_name: cta } }
                    );
                case 'on4':
                    let isNewDpp = document.querySelector('.newDpp');
                    const mrcUrl = __INITIAL_STATE__.appUrl + 'mortgage/mrc/?title=getpreapproved&fallback=getpreapproved_LP' + (isNewDpp ? '_NEW' : '');
                    return openLinkWithTrack(
                        { url: mrcUrl },
                        { ec: 'partner_mortgage_listings_session', en: 'long_form', el: 'MRC', props: { link_name: 'Get Preapproved - LP' } }
                    );
                case 'on5':
                    return openLinkWithTrack(
                        { url: `https://gardenstateloans.com/movoto/?utm_campaign=movoto_getpreapproved&zipcode=${sendOpt.zipcode}` },
                        { ec: 'partner_mortgage_listings_session', en: 'lender_site', el: 'Garden State', props: { link_name: cta } }
                    );
                case 'on7':
                    return openLinkWithTrack(
                        { url: `https://www.newamericanfunding.com/lfj/mov/?refinance=31946757&purchase=31946858&phone=8559503731&utm_source=MVTO&utm_medium=display&utm_content=default&utm_campaign=MVTO_ActiveListings&lp=pr&lt=all&sub_id=${sendOpt.price}&pub_id=getpreapproved&utm_term=default` },
                        { ec: 'partner_mortgage_listings_session', en: 'lender_site', el: 'New American Funding', props: { link_name: cta } }
                    );
                case 'on8':
                    return openLinkWithTrack(
                        { url: `https://ep.bankrate.com/hlink_redirects/brm_cpc.aspx?e=DG%2Bz7T9Zk6puw8AsmhIEvei5A2IDvbb%2B0sbrYRo5meGjtSAO4pyD3xlEK%2BWguEY99Jp%2BHaCtbREZOraip8uB2BEZfUkHyGu55CNNTZh3UIZGyaydCyh4TpTVDAq%2B%2FehZtBHIP%2Bs8nLWyRd4IK5v3qs4hES13t6laXaCIW%2FYhec6F5xViKHngUJfVNDh0ADWsQgUa9KjL%2FOzwHPGPmtpi0lcERTNfojNZyopJZtYhZeUNh0fVd5S3IzN%2B%2BBZUaSepk9Hfs4502Xgg4oWxeOKDx4I6fDIfBh947qZDlwX67fH7zrKz3925oy2xha%2FUC%2FuxwrGIK5qtoPKTXearpzSiGzpy74GZyfyNv0no%2FVg0vePfKCEdEbugQ4WlL%2FWOZoYYUB%2BrlsnOHWUTn01dW01eN7PCelBCyZX%2FIMuCllgZFig%2FZ39Fn%2BAl02vkILsokrLss%2B7Bmn5T%2BEv2R3foIuSBpw%3D%3D&reqid=10000000-1000-4000-8000-100000169977&lead=1&adsetid=169977&adsettype=cost_per_click&cs=0&pc=2&c=%2C%2C%2C%2Chttps%3A%2F%2Fwww.rocketmortgage.com%2Falf%2Fwham-orbit%2FloanType%3Fqpv%3Dblocknomovement%26qls%3DMVT_affrktxx.movotopurc%26aff_sub%3D%257Bbrlid%257D%26reqId%3D10000000-1000-4000-8000-100000169977%2C&iscpl=false&points=&dti=&directlink=true` },
                        { ec: 'partner_mortgage_listings_session', en: 'lender_site', el: 'Quicken', props: { link_name: 'Get Preapproved_3CTAtest' } }
                    );
                case 'on9':
                    return openLinkWithTrack(
                        { url: `https://links.cordlessmedia.com/staticlink/89984/89984_textLink_10/GetPreApproved/mortgage?zipcode=${sendOpt.zipcode}&state=${sendOpt.state}&price=${sendOpt.price}` },
                        { ec: 'partner_mortgage_listings_session', en: 'lender_site', el: 'Cordless', props: { link_name: 'Get Preapproved_3CTAtest' } }
                    );
                case 'on11':
                    return openLinkWithTrack(
                        { url: `https://districtlending.itclix.com/#home?zipcode=${sendOpt.zipcode}&utm_campaign=getpreapproved` },
                        { en: 'lender_site_lead_pop', ec: 'partner_mortgage_listings_session', en: 'lender_site_lead_pop', el: 'District Lending', props: { link_name: cta } }
                    );
                default:
                    break;
            }
        } else if(!isMinorProperty && sendOpt.triggerId === 'findalender' && context.splits['movoto-cpc-lender-cw-9242'] === 'on'){
            let linkToOpen = `https://ep.bankrate.com/hlink_redirects/brm_cpc.aspx?e=DG%2Bz7T9Zk6puw8AsmhIEvei5A2IDvbb%2B0sbrYRo5meGjtSAO4pyD3xlEK%2BWguEY99Jp%2BHaCtbREZOraip8uB2BEZfUkHyGu55CNNTZh3UIZGyaydCyh4TpTVDAq%2B%2FehZtBHIP%2Bs8nLWyRd4IK5v3qs4hES13t6laXaCIW%2FYhec6F5xViKHngUJfVNDh0ADWsQgUa9KjL%2FOzwHPGPmtpi0lcERTNfojNZyopJZtYhZeUNh0fVd5S3IzN%2B%2BBZUaSepk9Hfs4502Xgg4oWxeOKDx4I6fDIfBh947qZDlwX67fH7zrKz3925oy2xha%2FUC%2FuxwrGIK5qtoPKTXearpzSiGzpy74GZyfyNv0no%2FVg0vePfKCEdEbugQ4WlL%2FWOZoYYUB%2BrlsnOHWUTn01dW01eN7PCelBCyZX%2FIMuCllgZFig%2FZ39Fn%2BAl02vkILsokrLss%2B7Bmn5T%2BEv2R3foIuSBpw%3D%3D&reqid=10000000-1000-4000-8000-100000169977&lead=1&adsetid=169977&adsettype=cost_per_click&cs=0&pc=2&c=%2C%2C%2C%2Chttps%3A%2F%2Fwww.rocketmortgage.com%2Falf%2Fwham-orbit%2FloanType%3Fqpv%3Dblocknomovement%26qls%3DMVT_affrktxx.movotopurc%26aff_sub%3D%257Bbrlid%257D%26reqId%3D10000000-1000-4000-8000-100000169977%2C&iscpl=false&points=&dti=&directlink=true&utm_medium=web&utm_source=movoto&utm_campaign=findalender`
            if(sendOpt.isAppendUtmCta) {
                linkToOpen += `&utmcta=${sendOpt.triggerId}SF`
            }
            $eventBus.$emit('ga', { ec: 'partner_mortgage_listings_session', en: 'lender_site', el: 'Quicken', props:{link_name: 'Find a lender'}});
            window.open(linkToOpen, '_blank');
            return;
        } else if (!isMinorProperty && sendOpt.triggerId === 'findalender' && context.splits['movoto-cpc-lender-cw-9242'] === 'on1') {
            return openLinkWithTrack(
                { url: 'https://www.rocketmortgage.com/alf/wham-orbit/loanType?qpv=blocknomovement&qls=MVT_affrktxx.movotopurc&aff_sub=' },
                { ec: 'partner_mortgage_listings_session', en: 'lender_site', el: 'Rocket', props: { link_name: 'Find a lender' } }
            );
        } else if (!isMinorProperty && sendOpt.triggerId === 'findalender' && context.splits['movoto-cpc-lender-cw-9242'] === 'on2') {
            return openLinkWithTrack(
                { url: 'https://onboarding.ownup.com/?utm_source=movoto&utm_medium=partnerchannel&utm_campaign=pilot&utm_term=findalender&utm_content=direct' },
                { ec: 'partner_mortgage_listings_session', en: 'long_form', el: 'OwnUp', props: { link_name: 'Find a Lender' } }
            );
        } else if (!isMinorProperty && sendOpt.triggerId === 'findalender' && context.splits['movoto-cpc-lender-cw-9242'] === 'on3') {
            const emailSha256 = context && context.user && context.user.sha256 ? `&cme=${context.user.sha256}` : '';
            window.open(`https://links.cordlessmedia.com/staticlink/89984/89984_textLink_11/FindALender/mortgage?zipcode=${sendOpt.zipcode}&state=${sendOpt.state}&city=${sendOpt.city}&address=${sendOpt.address}&price=${sendOpt.price}${emailSha256}`, '_blank');
            $eventBus.$emit('ga', { ec: 'partner_mortgage_listings_session', en: 'lender_site', el: 'Cordless', props: { link_name: 'Find a lender' } });
            return;
        } else if (!isMinorProperty && sendOpt.triggerId === 'findalender' && context.splits['movoto-cpc-lender-cw-9242'] === 'on4') {
            let isNewDpp = document.querySelector('.newDpp');
            window.open(__INITIAL_STATE__.appUrl + 'mortgage/mrc/?title=findlender&fallback=findlender_LP' + (isNewDpp ? '_NEW' : ''), '_blank');
            $eventBus.$emit('ga', { ec: 'partner_mortgage_listings_session', en: 'Long Form', el: 'MRC', props: { link_name: 'Find a Lender - LP' } });
            return;
        } else if (!isMinorProperty && sendOpt.triggerId === 'findalender' && context.splits['movoto-cpc-lender-cw-9242'] === 'on5') {
            window.open(`https://gardenstateloans.com/movoto/?utm_campaign=movoto_findlender&zipcode=${sendOpt.zipcode}`, '_blank');
            $eventBus.$emit('ga', { ec: 'partner_mortgage_listings_session', en: 'lender_site', el: 'Garden State', props: { link_name: 'Find a lender' } });
            return;
        } else if (!isMinorProperty && sendOpt.triggerId === 'findalender' && context.splits['movoto-cpc-lender-cw-9242'] === 'on7') {
            window.open(`https://www.newamericanfunding.com/lfj/mov/?refinance=31946757&purchase=31946858&phone=8559503731&utm_source=MVTO&utm_medium=display&utm_content=default&utm_campaign=MVTO_ActiveListings&lp=pr&lt=all&sub_id=${sendOpt.price}&pub_id=findalender&utm_term=default`, '_blank');
            $eventBus.$emit('ga', { ec: 'partner_mortgage_listings_session', en: 'lender_site', el: 'New American Funding', props: { link_name: 'Find a lender' } });
            return;
        } else if (!isMinorProperty && sendOpt.triggerId === 'findalender' && context.splits['movoto-cpc-lender-cw-9242'] === 'on8') {
            $eventBus.$emit('ga', { ec: 'partner_mortgage_listings_session', en: 'lender_site', el: 'Quicken', props: { link_name: 'Find a lender_3CTAtest' } });
            window.open(`https://ep.bankrate.com/hlink_redirects/brm_cpc.aspx?e=DG%2Bz7T9Zk6puw8AsmhIEvei5A2IDvbb%2B0sbrYRo5meGjtSAO4pyD3xlEK%2BWguEY99Jp%2BHaCtbREZOraip8uB2BEZfUkHyGu55CNNTZh3UIZGyaydCyh4TpTVDAq%2B%2FehZtBHIP%2Bs8nLWyRd4IK5v3qs4hES13t6laXaCIW%2FYhec6F5xViKHngUJfVNDh0ADWsQgUa9KjL%2FOzwHPGPmtpi0lcERTNfojNZyopJZtYhZeUNh0fVd5S3IzN%2B%2BBZUaSepk9Hfs4502Xgg4oWxeOKDx4I6fDIfBh947qZDlwX67fH7zrKz3925oy2xha%2FUC%2FuxwrGIK5qtoPKTXearpzSiGzpy74GZyfyNv0no%2FVg0vePfKCEdEbugQ4WlL%2FWOZoYYUB%2BrlsnOHWUTn01dW01eN7PCelBCyZX%2FIMuCllgZFig%2FZ39Fn%2BAl02vkILsokrLss%2B7Bmn5T%2BEv2R3foIuSBpw%3D%3D&reqid=10000000-1000-4000-8000-100000169977&lead=1&adsetid=169977&adsettype=cost_per_click&cs=0&pc=2&c=%2C%2C%2C%2Chttps%3A%2F%2Fwww.rocketmortgage.com%2Falf%2Fwham-orbit%2FloanType%3Fqpv%3Dblocknomovement%26qls%3DMVT_affrktxx.movotopurc%26aff_sub%3D%257Bbrlid%257D%26reqId%3D10000000-1000-4000-8000-100000169977%2C&iscpl=false&points=&dti=&directlink=true`, '_blank');
            return;
        } else if (!isMinorProperty && sendOpt.triggerId === 'findalender' && context.splits['movoto-cpc-lender-cw-9242'] === 'on9') {
            window.open(`https://links.cordlessmedia.com/staticlink/89984/89984_textLink_11/FindALender/mortgage?zipcode=${sendOpt.zipcode}&state=${sendOpt.state}&price=${sendOpt.price}`, '_blank');
            $eventBus.$emit('ga', { ec: 'partner_mortgage_listings_session', en: 'lender_site', el: 'Cordless', props: { link_name: 'Find a lender_3CTAtest' } });
            return;
        }  else if (!isMinorProperty && sendOpt.triggerId === 'findalender' && context.splits['movoto-cpc-lender-cw-9242'] === 'on11') {
            window.open(`https://districtlending.itclix.com/#home?zipcode=${sendOpt.zipcode}&utm_campaign=findlender`, '_blank');
            $eventBus.$emit('ga', { en: 'lender_site_lead_pop', ec: 'partner_mortgage_listings_session', el: 'District Lending', props: { link_name: 'Find a lender' } });
            return;
        } else if(sendOpt.triggerId === 'veteranseligibility1') {
            window.open('https://ad.doubleclick.net/ddm/trackclk/N7384.146215MOVOTO/B27281448.383026519;dc_trk_aid=573901919;dc_trk_cid=206110318;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;ltd=;dc_tdv=1', '_blank');
            $eventBus.$emit('ga', { ec: 'partner_mortgage_listings_session', en: 'lender_site', el:  elDict[sendOpt.triggerId] || elDict[url] || url, props: props } );
            return;
        } else if(sendOpt.triggerId === 'veteranseligibility1NewDpp') {
            window.open('https://ad.doubleclick.net/ddm/trackclk/N7384.146215MOVOTO/B27281448.402829196;dc_trk_aid=594717722;dc_trk_cid=221874282;dc_lat=;dc_rdid=;', '_blank');
            $eventBus.$emit('ga', { ec: 'partner_mortgage_listings_session', en: 'lender_site', el:  elDict[sendOpt.triggerId] || elDict[url] || url, props: props } );
            return;
        } else if(sendOpt.triggerId === 'veteranseligibility2') {
            // url = `https://ad.doubleclick.net/ddm/trackclk/N7384.146215MOVOTO/B27281448.359548222;dc_trk_aid=550413068;dc_trk_cid=186553192;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;ltd=`
            window.open('https://ad.doubleclick.net/ddm/trackclk/N7384.146215MOVOTO/B27281448.392850339;dc_trk_aid=584162619;dc_trk_cid=213404959;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;ltd=;dc_tdv=1', '_blank');
            $eventBus.$emit('ga', { ec: 'partner_mortgage_listings_session', en: 'lender_site', el:  elDict[sendOpt.triggerId] || elDict[url] || url, props: props } );
            return;
        } else if(sendOpt.triggerId === 'getquotes') {
            if(url === 'insurance1'){
                $.loadInsurancePop(sendOpt.zipcode, 'getquotes_dpp');
                return;
            }else if(url === 'insurance2'){
                $.loadInsurancePop(sendOpt.zipcode, 'getaffordableinsurance_thankyoupage');
                return;
            }
        } else if(sendOpt.triggerId === 'lowerpayment'){
        }else{
            url && $eventBus.$emit('ga', { ec: 'partner', en: 'partner_lead_created', el:  elDict[sendOpt.triggerId] || elDict[url] || url, props: props } );
        }

        //only overwrite default case for thoes cases where we need to open a new tab
        if (['apply', 'getpreapproved', 'findalender'].includes(sendOpt.triggerId)) {
            $.loadDefaultMRCPopUp(sendOpt.triggerId, sendOpt.cta);
            return;
        }

        if(url === 'betterfinance' || url === 'betterpreapproved'){
            $.loadMortgagePopUp(sendOpt.triggerId, sendOpt.pid, sendOpt.cta);
            return;
        } else if (url === 'refinance'){
            sendOpt.pid = 'myfi_movoto';
            $.loadMortgagePopUp(sendOpt.triggerId, sendOpt.pid, sendOpt.cta);
            return;
        } else if(url == 'preapprove' && sendOpt.isAppendUtmCta && (sendOpt.triggerId == 'calculatepayment' || sendOpt.triggerId == 'getpreapproved' || sendOpt.triggerId == 'prequalify' || sendOpt.triggerId == 'findalender')) {
            sendOpt.pid = 'myfi_movoto';
            $.loadMortgagePopUp(sendOpt.triggerId, sendOpt.pid, sendOpt.cta);
            return;
        }
         else if (['preapproveCalc', 'preapprove'].includes(url)){
            if((['MOBILE', 'LAND'].includes(sendOpt.propertyType))){
                $.loadMortgagePopUp(sendOpt.triggerId, sendOpt.pid, sendOpt.cta);
            }
            return;
        } else if(url === 'prequalifylandmobile'){
            $.loadMortgagePopUp(sendOpt.triggerId, sendOpt.pid, sendOpt.cta);
            return;
        } else if(url === 'myfinance') {
            $.loadMortgagePopUp(sendOpt.triggerId, sendOpt.pid, sendOpt.cta);
            return;
        } else if(url === 'rentalCredit'){
            var dict1 = {
                checkrentalCredit: 'rentaldppatf'
            };
            var subId1 = dict1[sendOpt.triggerId] || sendOpt.triggerId;
            window.open(`https://www.gofreecredit.com/tu/r/f65e0b6641b23029484e89ee258fd7e8?pid=${sendOpt.pid}&fname=${sendOpt.firstName}&lname=${sendOpt.lastName}&zip=${sendOpt.zipcode}&email=${sendOpt.email}&phone1=${sendOpt.phone}&address1=&city=${sendOpt.city}&state=${sendOpt.state}&subid=${subId1}`, '_blank');
            return;
        } else if (sendOpt.url === 'veteranslink2'){
            window.open('https://ad.doubleclick.net/ddm/trackclk/N7384.146215MOVOTO/B27281448.333845379;dc_trk_aid=525666632;dc_trk_cid=169972431;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;ltd=', '_blank');
            return;
        } else if (sendOpt.url === 'veteranslink3'){
            window.open('https://ad.doubleclick.net/ddm/trackclk/N7384.146215MOVOTO/B27281448.334076439;dc_trk_aid=525934527;dc_trk_cid=169752983;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;ltd=', '_blank');
            return;
        } else if(sendOpt.url === 'veteransUnited'){
            var params = {};
            if(sendOpt.triggerId === 'veteranseligibility'){
                params = {nid: '4411758', bid: '328819326', aid: '521119045', cid: '166903281'};
                window.open(`https://ad.doubleclick.net/ddm/trackclk/N7384.${params.nid}MOVOTO_AFFORDABILIT/B27281448.${params.bid};dc_trk_aid=${params.aid};dc_trk_cid=${params.cid};dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;ltd=`, '_blank');
                return;
            } else if(sendOpt.triggerId === 'veteranscanafford'){
                window.open('https://ad.doubleclick.net/ddm/trackclk/N7384.146215MOVOTO/B27281448.392850873;dc_trk_aid=584163027;dc_trk_cid=214030662;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;ltd=;dc_tdv=1', '_blank');
                $eventBus.$emit('ga', { ec: 'partner_mortgage_listings_session', en: 'lender_site', el:  elDict[sendOpt.triggerId] || elDict[url] || url, props: props } );
                return;
            } else if(sendOpt.triggerId === 'veteranscanafford1'){
                window.open('https://ad.doubleclick.net/ddm/trackclk/N7384.146215MOVOTO/B27281448.333845379;dc_trk_aid=525666632;dc_trk_cid=169972431;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;ltd=', '_blank');
                return;
            } else if(sendOpt.triggerId === 'veteranscanafford2'){
                window.open('https://ad.doubleclick.net/ddm/trackclk/N7384.146215MOVOTO/B27281448.334076439;dc_trk_aid=525934527;dc_trk_cid=169752983;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;ltd=', '_blank');
                return;
            }else if(sendOpt.triggerId === 'veteranscanafford3'){
                window.open('https://ad.doubleclick.net/ddm/trackclk/N7384.146215MOVOTO/B27281448.334077147;dc_trk_aid=526252873;dc_trk_cid=169755869;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;ltd=', '_blank');
                $eventBus.$emit('ga', { ec: 'partner_mortgage_listings_session', en: 'lender_site', el:  elDict[sendOpt.triggerId] || elDict[url] || url, props: props } );
                return;
            } else if(sendOpt.triggerId === 'veteranscanafford6'){
                window.open('https://ad.doubleclick.net/ddm/trackclk/N7384.146215MOVOTO/B27281448.388062853;dc_trk_aid=579360909;dc_trk_cid=210563133;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;ltd=;dc_tdv=1', '_blank');
                $eventBus.$emit('ga', { ec: 'partner_mortgage_listings_session', en: 'lender_site', el:  elDict[sendOpt.triggerId] || elDict[url] || url, props: props } );
                return;
            } else if(sendOpt.triggerId === 'veteranscanafford6NewDpp'){
                window.open('https://ad.doubleclick.net/ddm/trackclk/N7384.146215MOVOTO/B27281448.403100178;dc_trk_aid=594676090;dc_trk_cid=221277862;dc_lat=;dc_rdid=;', '_blank');
                $eventBus.$emit('ga', { ec: 'partner_mortgage_listings_session', en: 'lender_site', el:  elDict[sendOpt.triggerId] || elDict[url] || url, props: props } );
                return;
            }
        } else if(url === 'veteransUnited2'){
            if(sendOpt.triggerId === 'veteranseligibility3') {
                window.open('https://ad.doubleclick.net/ddm/trackclk/N7384.146215MOVOTO/B27281448.335741447;dc_trk_aid=527838432;dc_trk_cid=171090347;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;ltd=', '_blank');
                return;
            }
        } else if (sendOpt.url === 'veteransUnited3') {
            window.open('https://ad.doubleclick.net/ddm/trackclk/N7384.146215MOVOTO/B27281448.334076439;dc_trk_aid=525934527;dc_trk_cid=169752983;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;ltd=', '_blank');
            $eventBus.$emit('ga', { ec: 'partner', en: 'mortgage_session', el: 'VeteransUnited_TYP_V2', props: { link_name: sendOpt.cta } });
            return;
        } else if (sendOpt.url === 'veteransUnited4') {
            window.open('https://ad.doubleclick.net/ddm/trackclk/N7384.146215MOVOTO/B27281448.351639291;dc_trk_aid=542729385;dc_trk_cid=181639877;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;ltd=', '_blank');
            $eventBus.$emit('ga', { ec: 'partner', en: 'mortgage_session', el: 'VeteransUnited_TYP_V3', props: { link_name: sendOpt.cta } });
            return;
        }

        if(sendOpt.utm_email){
            url += `&utm_email=${sendOpt.utm_email}`;
        }
        if(sendOpt.utm_notification){
            url += `&utm_notification=${sendOpt.utm_notification}`;
        }

        if(sendOpt.pageType && url && !url.includes('www.veteransunited.com')){
            url += `&pageType=${sendOpt.pageType}`;
        }

        if (context.user && context.user.id) {
            url += `&ojo-uid=${context.user.id}`;
        }
        const contextObj = {
                            propertyType: sendOpt.propertyType,
                            propertyValue: sendOpt.price || '',
                            propertyCity: sendOpt.city || '',
                            propertyState: sendOpt.state || '',
                            zipcode: sendOpt.zipcode || sendOpt.zip || '',
                            purchasePrice: sendOpt.price || '',
                            email: sendOpt.email || '',
                            phone: sendOpt.phone || '',
                            firstName: sendOpt.firstName || '',
                            lastName: sendOpt.lastName || ''
                        };
        const postData = {
            name: category ? 'SHOW_EMBEDDED_WIDGET' : 'SHOW_EMBEDDED_FORM',
            triggerId: `${sendOpt.triggerId}${sendOpt.isAppendUtmCta ? 'SF' :''}`,
            category: category,
            formUrl: category ? undefined : url,
            context: contextObj
        };

        $.loadBankratePop().then(function(){
            console.log(postData);
            window.postMessage(postData, '*');
        });
    }
};
