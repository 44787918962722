// any issue here please contact with conjee
// https://css-tricks.com/prevent-page-scrolling-when-a-modal-is-open/
var _mobileSafari = null;
var isMobileSafari = function () {
    if (_mobileSafari === null) {
        _mobileSafari = !!(/safari/i.test(navigator.userAgent) && 'ontouchstart' in window);
    }
    return _mobileSafari;
};
const preventDefault = (event) => {
    //if parent node not include '.overlay' '.dialog' '.mvt-searchbox' then prevent default with vanilla js
    if (!event.target.closest('.overlay') && !event.target.closest('.dialog') && !event.target.closest('.mvt-searchbox')) {
        event.preventDefault();
    }
};

function disableScroll() {
    if (document.body.style.overflow === 'hidden' && document.body.style.height === '100%') {
        return;
    }
    document.body.style.overflow = 'hidden';
    document.body.style.height = '100%';

    window.addEventListener('touchmove', preventDefault, { passive: false });
}

function enableScroll() {
    if (document.body.style.overflow === '' && document.body.style.height === '') {
        return;
    }
    document.body.style.overflow = '';
    document.body.style.height = '';

    window.removeEventListener('touchmove', preventDefault);
}

export default {
    openOverlayer: function (name) {
        if (isMobileSafari()) {
            disableScroll();
        }
        $('#body').addClass(name);
    },
    closeOverlayer: function (name) {
        var $body = $('#body');
        $body.removeClass(name);
        if (isMobileSafari()) {
            if ($body.hasClass('overlay-active') || $body.hasClass('aside-active') || $body.hasClass('dialog-active') || $body.hasClass('searchbox-active') || $body.hasClass('popownup-active')) {
                return;
            }
            enableScroll();
        }
    },
};
