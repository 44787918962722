import { initalStorage, getStorage, setStorage, removeStorage, setSessionStorage, getSessionStorage } from '../nojq/_localstorage.js';

export default {
    localStorage: null,
    initalStorage: initalStorage,
    setStorage: setStorage,
    getStorage: getStorage,
    removeStorage: removeStorage,
    setSessionStorage: setSessionStorage,
    getSessionStorage: getSessionStorage,
};
